@import "../../utils/variables";

.menu {
    position: fixed;
    left: 0;
    top: 0;
    background: #272727;
    max-width: 336px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: $noneActive;
    font-weight: 700;
    &__logo {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    &__ul {
        margin: 0;
        padding: 20px 0;
    }
    &__li {
        list-style: none;
        padding: 0 20px;
    }
    &__link {
        padding: 17px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: flex-end;
        line-height: 1;
        color: $noneActive;
        svg path {
            fill: $noneActive;
            transition: all .3s ease;
        }
        &.active,
        &:hover {
            color: $red;
            svg path {
                fill: $red;
            }
        }
    }
    &__icon {
        margin-right: 10px;
        display: flex;
        min-width: 16px;
    }
    .menu__dropdown {
        padding-left: 25px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: 12px;
        padding-bottom: 12px;
        &-li {
            a {
                padding: 5px 0;
                border-bottom: 0;
                align-items: center;
            }
        }
    }
}