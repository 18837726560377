.create-project {
    background: #FFFFFF;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    padding: 40px 20px 20px;
    margin-bottom: 20px;
    &__wrap {
        &.android {
            .create-project__label {
                margin-right: 8px;
                min-width: 180px;
            }
        }
    }
    &__title {
        font-size: 24px;
        margin-bottom: 40px;
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 40px;
    }
    &__item {
        flex: 0 0 50%;
        max-width: 50%;
        &:last-child {
            .create-project__content {
                padding-right: 0;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        padding-right: 38px;
        .input-field {
            width: 100%;
            margin: 0;
            margin-bottom: 20px;
            .helper-text {
                display: inline;
            }
        }
    }
    &__label {
        margin-top: 14px;
        margin-right: 32px;
        min-width: 134px;
    }
    &__btn {
        &-row {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .btn {
                max-width: 236px;
                width: 100%;
                text-align: center;
                justify-content: center;
                padding: 10px;
            }
        }
    }
}