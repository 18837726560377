.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    position: fixed;
    top: 30px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    color: #fff;
    background-color: #f44336;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 8%), 0px 6px 10px 0px rgb(0 0 0 / 8%), 0px 1px 18px 0px rgb(0 0 0 / 6%);
    &.success {
        background-color: #4caf50;
    }
    &.error {
        background-color: #f44336;
    }
    &.show {
        display: flex;
    }
    &.hide {
        display: none;
    }
    &__close {
        cursor: pointer;
        font-size: 1.2rem;
        margin-left: 10px;
        margin-right: -8px;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease;
        border-radius: 50%;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}