.support {
    display: flex;
    flex-wrap: wrap;
    &__item {
        flex: 0 0 342px;
        max-width: 342px;
    }
    &__title {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 48px;
    }
    &__li {
        margin-bottom: 5px;
    }
}
.registration__btn-row {
    justify-content: center;
    margin-top: 30px;
}