#deleteProjectModal {
    max-width: 450px;
    h4 {
        font-size: 22px;
        font-weight: 500;
    }
    .modal-content {
        padding-bottom: 10px;
    }
    .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding: 10px 15px;
        padding-top: 0;
        height: auto;
        button {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}