.projects {
    padding-right: 58px;
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 48px;
        .title__row {
            margin-bottom: 0;
        }
    }
}