@import "../../utils/variables";

.filter {
    &__row {
        display: flex;
        align-items: center;
        .btn {
            padding: 0 30px;
        }
        .input-field {
            max-width: 306px;
            width: 100%;
            margin: 0;
            margin-right: 50px;
        }
        .select-wrapper input.select-dropdown {
            margin-bottom: 0;
        }
    }
}
.project-selected {
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &__no {
        background: #F9F9F9;
        border-radius: 60px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 336px;
        width: 100%;
        padding: 0 20px;
        color: $noneActive;
    }
}
.project {
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        box-sizing: border-box;
    }
    &__item {
        background: #FFFFFF;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
        padding: 20px;
        flex: 0 0 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    &__icon {
        display: flex;
        margin-right: 10px;
    }
    &__chart {
        margin-top: 15px;
    }
}
.sapmle-codes {
    padding: 64px 20px 80px;
    &__row {
        margin-bottom: 54px;
        text-align: center;
    }
    &__title {
        font-size: 24px;
        margin-bottom: 10px;
    }
    &__desc {
        color: $noneActive;
        margin-bottom: 10px;
    }
}
.sapmle-code {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__item {
        flex: 0 0 335px;
        max-width: 335px;
        margin-right: 20px;
        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }
    &__title {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 38px;
    }
    &__desc {
        margin-bottom: 40px;
    }
}