@import "../../utils/variables";

.authorization {
    max-width: 698px;
    width: 100%;
    margin: auto;
    padding: 70px 15px 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    &__logo {
        text-align: center;
        margin-bottom: 5rem;
    }
    &__title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 2rem;
    }
    &__content {
        background: #FFFFFF;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
        max-width: 342px;
        width: 100%;
        margin: auto;
        padding: 40px 25px;
    }
    &__forgot {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 64px;
        [type=checkbox] + span:not(.lever) {
            font-size: 13px;
        }
    }
    &__link {
        font-size: 13px;
        color: #4A4A4A;
        cursor: pointer;
    }
    &__create {
        color: $noneActive;
        margin-bottom: 40px;
        &-link {
            font-weight: 700;
            text-decoration: underline;
        }
    }
    .input-field {
        margin-bottom: 30px;
    }
    .btn {
        width: 100%;
       justify-content: center;
    }
}