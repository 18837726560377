.canvasjs-chart-toolbar {
    display: none;
}
.canvasjs-chart-credit {
    display: none;
}
.graphs-charts-empty {
    width: 100%;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}