@import "../../utils/variables";


.registration {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    &__header {
        padding: 26px 43px 8px;
    }
    &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__account {
        color: $noneActive;
        margin-right: 20px;
    }
    &__container {
        max-width: 698px;
        width: 100%;
        margin: auto;
        padding: 0 15px
    }
    &__logo {
        text-align: center;
        margin-bottom: 50px;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__form {
        max-width: 342px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
        padding: 24px 25px 40px;
    }
    &__info {
        max-width: 292px;
        margin-right: 32px;
    }
    .input-field {
        margin-top: 0;
        margin-bottom: 12px;
    }
    &__title {
        font-size: 24px;
        margin-bottom: 24px;
    }
    &__list {
        margin: 0;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-weight: 700;
    }
    &__icon {
        display: flex;
        margin-right: 10px;
    }
    &__agreement {
        color: $noneActive;
        max-width: 267px;
        margin: auto;
        margin-top: 32px;
        margin-bottom: 40px;
        font-size: 13px;
        a {
            color: $noneActive;
            text-decoration: underline;
        }
    }
    &__btn-row {
        display: flex;
        align-items: center;
        label {
            display: flex;
            align-items: center;
        }
    }
    &__partner {
        width: 100%;
        margin-top: 40px;
        &-title {
            text-align: center;
            margin-bottom: 8px;
        }
        &-row {
            display: flex;
            justify-content: space-around;
        }
        &-item {
            margin: 30px;
        }
    }
}