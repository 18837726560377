@import "../../utils/variables";

.projects-table {
    background: #FFFFFF;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    thead {
        border-bottom: 1px solid #E3E3E3;
    }
    th,
    td {
        padding: 20px;
    }
    th {
        font-weight: 400;
    }
    strong {
        font-weight: 700;
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__item {
        margin-right: 15px;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &.audio-icon {
            svg {
                path {
                    fill: #F2C94C;
                }
            }
        }
        &.video-icon {
            svg {
                path {
                    fill: #DF434B;
                }
            }
        }
        &.done-icon {
            svg {
                path {
                    fill: transparent;
                    stroke: #6FCF97;
                }
            }
        }
        svg {
            path {
                fill: #E3E3E3;
            }
        }
    }
}
.empty-projects {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}