#forgotPassword {
    max-width: 342px;
    .modal-footer,
    h4 {
        display: none;
    }
    .forgot-password {
        position: relative;
        &__close {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            font-size: 30px;
        }
        &__text {
            margin: 0;
            padding-top: 30px;
            color: #858C91;
            font-size: 16px;
            margin-bottom: 20px;
        }
        &__wrap {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}
.verification {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    &__text {
        color: #858C91;
        text-align: left;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    &__wrap {
        display: flex;
        justify-content: center;
    }
}