@import "../../utils/variables";

.billing {
    max-width: 454px;
    width: 100%;
    &__row {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 454px;
        max-width: 454px;
        margin-left: -10px;
        margin-right: 10px;
    }
    &__item {
        background: #FFFFFF;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
        padding: 20px;
        margin: 10px;
    }
    &__title {
        text-align: center;
        margin-bottom: 74px;
    }
    &__price {
        font-family: 'Bebas Neue', cursive;
        font-size: 48px;
        color: $accent;
        text-align: center;
        margin-bottom: 48px;
        span {
            color: $text;
        }
    }
    &__desc {
        color: $noneActive;
    }
    &__btn {
        margin-top: 30px;
        margin-right: 20px;
        float: right;
    }
}