@import "../../utils/variables";

.title {
    font-size: 32px;
    font-weight: 700;
    &__row {
        margin-bottom: 48px;
    }
}
.desc {
    color: $noneActive;
    font-size: 24px;
}