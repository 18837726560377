.settings {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    &__item {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .create-project {
        &__item {
            flex: 0 0 100%;
            max-width: 100%;
        } 
    }
    &__btn {
        &-row {
            display: flex;
            justify-content: flex-end;
        }
    }
}